export const LAPLAND_GIFTS_REELS: { [key: string]: string } = {
  a: '/assets/reel-symbols/lapland-gifts/scatter.png',
  b: '/assets/reel-symbols/lapland-gifts/wild.png',
  c: '/assets/reel-symbols/lapland-gifts/h1.png',
  d: '/assets/reel-symbols/lapland-gifts/h2.png',
  e: '/assets/reel-symbols/lapland-gifts/h3.png',
  f: '/assets/reel-symbols/lapland-gifts/h4.png',
  g: '/assets/reel-symbols/lapland-gifts/a.png',
  h: '/assets/reel-symbols/lapland-gifts/k.png',
  i: '/assets/reel-symbols/lapland-gifts/q.png',
  j: '/assets/reel-symbols/lapland-gifts/j.png',
  k: '/assets/reel-symbols/lapland-gifts/10.png',
};
