import { Injectable } from '@angular/core';
import { AppHistoryCurrentService } from '../../../shared/api/services/app-history-current.service';
import { Observable } from 'rxjs';
import { ApiSpinDataRespModel } from '../../../shared/api/models/api-spin-data-resp.model';

@Injectable()
export class HistoryCurrentService {

  constructor(
    private appHistoryCurrentService: AppHistoryCurrentService,
  ) {
  }

  public getDataCurrentHistoryWithCount(params): Observable<[ApiSpinDataRespModel, number]> {
    return this.appHistoryCurrentService.getDataCurrentHistoryWithCount(params);
  }
}
