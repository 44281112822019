import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { GameHistoryService } from './game-history.service';
import { AuthService } from '../../shared/api/services/auth.service';
import jwt_decode from 'jwt-decode';
import { ROLES_CONFIG } from '../../shared/configs/roles-config';
import { TokenDecodedModel } from '../../shared/api/models/token-decoded.model';
import { ITokenDecoded } from '../../shared/api/interfaces/token-decoded.interface';
import { HISTORY_TAB_CONFIG } from '../../shared/configs/history-tab.config';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-game-history',
  templateUrl: './game-history.component.html',
  styleUrls: ['./game-history.component.scss'],
})
export class GameHistoryComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public buttonsGroup: UntypedFormGroup;
  public openSessionDetails = false;
  public spinId: any = null;
  public sessionId: string = null;
  private tempToken: string;
  public showForPlayers = false;
  public displayedTabs: { [key: string]: string } = HISTORY_TAB_CONFIG;
  public spinnerContent = true;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private gameHistoryService: GameHistoryService,
    private authService: AuthService,
    private translate: TranslateService,
  ) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.formChangeValue();
    this.getDataFromLink();
  }

  private initForm(): void {
    this.buttonsGroup = this.fb.group({
      buttons: [''],
    });
  }

  private formChangeValue(): void {
    this.addSubscription(
      this.buttonsGroup.valueChanges
        .subscribe(x => {
          this.openSessionDetails = false;
          this.spinId = null;
        }),
    );
  }

  public todaySpinId(spinId: string): void {
    this.spinId = spinId;
    this.openSessionDetails = true;
  }

  public currentSpinId(spinId: number): void {
    this.spinId = spinId;
    this.openSessionDetails = true;
  }

  public currentSessionId(sessionId: string): void {
    this.sessionId = sessionId;
  }

  private getDataFromLink(): void {
    this.addSubscription(
      this.activatedRoute.queryParams
        .subscribe((params: Params) => {
          if (params.token) {
            this.tempToken = params.token;
            this.setRegularToken(this.tempToken);
          }
          if (params.ln) {
            this.translate.use(params.ln);
          }
        }),
    );
  }

  private setRegularToken(tempToken: string): void {
    this.addSubscription(
      this.gameHistoryService.getRegularToken(tempToken)
        .pipe(finalize(() => this.spinnerContent = false))
        .subscribe((res: string) => {
          this.checkRole(res);
          this.authService.setRegularToken(res);
        }),
    );
  }

  private checkRole(token: string): void {
    const decodedToken: ITokenDecoded = jwt_decode(token);
    const role = new TokenDecodedModel(decodedToken);
    if (role.role === ROLES_CONFIG.PLAYER) {
      this.showForPlayers = true;
      this.buttonsGroup.controls.buttons.patchValue(HISTORY_TAB_CONFIG.CURRENT);
    } else {
      this.showForPlayers = false;
      this.buttonsGroup.controls.buttons.patchValue(HISTORY_TAB_CONFIG.HISTORY);
    }
  }

  private addSubscription(subscription: Subscription): void {
    this.subscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
