export const PANDA_RICHES_REELS: { [key: string]: string } = {
  a: '/assets/reel-symbols/panda-riches/scatter.png',
  b: '/assets/reel-symbols/panda-riches/wild.png',
  b_expanded: '/assets/reel-symbols/panda-riches/wild_expanded.png',
  c: '/assets/reel-symbols/panda-riches/h1.png',
  d: '/assets/reel-symbols/panda-riches/h2.png',
  e: '/assets/reel-symbols/panda-riches/h3.png',
  f: '/assets/reel-symbols/panda-riches/h4.png',
  g: '/assets/reel-symbols/panda-riches/h5.png',
  h: '/assets/reel-symbols/panda-riches/a.png',
  i: '/assets/reel-symbols/panda-riches/k.png',
  j: '/assets/reel-symbols/panda-riches/q.png',
  k: '/assets/reel-symbols/panda-riches/j.png',
  l: '/assets/reel-symbols/panda-riches/10.png',
  m: '/assets/reel-symbols/panda-riches/9.png',
};
