export const QUEENSLAND_GHOST_WIN_LINES: { [key: string]: string } = {
  1: '/assets/winning-lines/1.png',
  2: '/assets/winning-lines/2.png',
  3: '/assets/winning-lines/3.png',
  4: '/assets/winning-lines/4.png',
  5: '/assets/winning-lines/5.png',
  6: '/assets/winning-lines/6.png',
  7: '/assets/winning-lines/7.png',
  8: '/assets/winning-lines/8.png',
  9: '/assets/winning-lines/9.png',
  10: '/assets/winning-lines/10.png',
  11: '/assets/winning-lines/11.png',
  12: '/assets/winning-lines/12.png',
  13: '/assets/winning-lines/13.png',
  14: '/assets/winning-lines/14.png',
  15: '/assets/winning-lines/15.png',
  16: '/assets/winning-lines/16.png',
  17: '/assets/winning-lines/17.png',
  18: '/assets/winning-lines/18.png',
  19: '/assets/winning-lines/19.png',
  20: '/assets/winning-lines/20.png',
  21: '/assets/winning-lines/21.png',
  22: '/assets/winning-lines/22.png',
  23: '/assets/winning-lines/23.png',
  24: '/assets/winning-lines/24.png',
  25: '/assets/winning-lines/25.png',
  26: '/assets/winning-lines/26.png',
  27: '/assets/winning-lines/27.png',
  28: '/assets/winning-lines/28.png',
  29: '/assets/winning-lines/29.png',
  30: '/assets/winning-lines/30.png',
};
