import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryTodayComponent } from './history-today.component';
import { HistoryTodayService } from './history-today.service';
import { HttpClientModule } from '@angular/common/http';
import { ApiHistoryTodayService } from '../../../shared/api/services/api-history-today.service';
import { MatTableModule } from '@angular/material/table';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ComponentsModule } from '../../../shared/components/components.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    HistoryTodayComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    HttpClientModule,
    MatTableModule,
    InfiniteScrollModule,
    TranslateModule,
  ],
  providers: [
    ApiHistoryTodayService,
    HistoryTodayService,
    { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
  ],
  exports: [
    HistoryTodayComponent,
  ],
})
export class HistoryTodayModule {
}
