import { ScatterModel } from './scatter.model';
import { IScatter } from '../interfaces/scatter.interface';
import { WayModel } from './way.model';
import { IWin } from '../interfaces/win.interface';
import { IWay } from '../interfaces/way.interface';

export class WinModel {
  readonly ways: WayModel[];
  readonly scatters: ScatterModel[];

  constructor(input: IWin) {
    this.ways = input.ways.map((item: IWay) => new WayModel(item));
    this.scatters = input.scatters.map((item: IScatter) => new ScatterModel(item));
  }
}
