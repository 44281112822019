export const HISTORY_TODAY_PLAYER_TABLE: string[] = [
  'id',
  'datetime',
  // 'bet',
  'wonAmount',
  // 'newBalance',
];

export const HISTORY_TODAY_OPERATOR_TABLE: string[] = [
  'id',
  'datetime',
  'operatorName',
  'brandName',
  'playerId',
  'roundId',
  'gameName',
  'feature',
  'betType',
  // 'bet',
  'outcome',
  'wonAmount',
  // 'newBalance',
];
