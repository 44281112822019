import { IWay } from '../interfaces/way.interface';

export class WayModel {
  readonly id: number;
  readonly symbolId: string;
  readonly count: number;
  readonly reward: number;

  constructor(input: IWay) {
    this.id = input.id;
    this.symbolId = input.symbolId;
    this.count = input.count;
    this.reward = input.reward;
  }
}
