import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  HISTORY_TODAY_PLAYER_TABLE,
  HISTORY_TODAY_OPERATOR_TABLE,
} from '../../../shared/configs/table-headers/history-today-headers.config';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../shared/api/services/auth.service';
import { IPagination } from '../../../shared/api/interfaces/pagination-interface';
import { PAGINATION_CONFIG } from '../../../shared/configs/pagination-config';
import { SpinDataModel } from '../../../shared/api/models/spin-data.model';
import { HistoryTodayService } from './history-today.service';
import { BET_TYPE_MAP } from '../../../shared/constants/bet-type-map';


@Component({
  selector: 'app-history-today',
  templateUrl: './history-today.component.html',
  styleUrls: ['./history-today.component.scss'],
})
export class HistoryTodayComponent implements OnInit, OnDestroy {
  @Output() setSpinId: EventEmitter<string> = new EventEmitter<string>();
  private subscription: Subscription = new Subscription();
  private regularToken: { [key: string]: string } = { token: null };
  public displayedColumns: string[] = [];
  public pagination: IPagination = Object.assign({}, PAGINATION_CONFIG);
  public totalItems: number;
  public infiniteScrollStop = false;
  public dataSource: SpinDataModel[] = null;
  public chosenRow: number;
  public betTypeMap: { [key: string]: string } = BET_TYPE_MAP;
  public showForPlayer = false;

  constructor(
    private authService: AuthService,
    private historyTodayService: HistoryTodayService,
  ) {
  }

  public ngOnInit(): void {
    this.setRegularToken();
  }

  private setRegularToken(): void {
    this.addSubscription(
      this.authService.tokenInfo$
        .subscribe((res: string) => {
          if (res) {
            this.regularToken.token = res;
            this.setDataTodayWithCount();
          }
        }),
    );
  }

  private setDataTodayWithCount(): void {
    this.addSubscription(
      this.historyTodayService.getDataTodayWithCount({ ...this.pagination, ...this.regularToken })
        .subscribe(([dataToday, count]) => {
          if (this.checkDisplayedColumns(dataToday.metadata, HISTORY_TODAY_PLAYER_TABLE)) {
            this.displayedColumns = HISTORY_TODAY_PLAYER_TABLE;
            this.showForPlayer = true;
          }
          if (this.checkDisplayedColumns(dataToday.metadata, HISTORY_TODAY_OPERATOR_TABLE)) {
            this.displayedColumns = HISTORY_TODAY_OPERATOR_TABLE;
            this.showForPlayer = false;
          }
          this.dataSource = dataToday.items;
          this.pagination.take = dataToday.take;
          this.pagination.skip = dataToday.skip;
          this.totalItems = count;
        }),
    );
  }

  private checkDisplayedColumns(metadata: string[], config: string[]): boolean {
    try {
      if (metadata.length !== config.length) {
        throw new Error();
      }
      metadata.forEach(item => {
        if (!config.includes(item)) {
          throw new Error();
        }
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  public onScroll(): void {
    if (this.dataSource.length < this.totalItems) {
      this.pagination.skip += this.pagination.take;
      this.loadMore();
    }
  }

  private loadMore(): void {
    this.addSubscription(
      this.historyTodayService.getDataTodayWithCount({ ...this.pagination, ...this.regularToken })
        .subscribe(([dataToday, count]) => {
          this.dataSource = this.dataSource.concat(dataToday.items);
        }),
    );
  }

  public getSpinId(id: string, rowIndex: number): void {
    this.chosenRow = rowIndex;
    this.setSpinId.emit(id);
  }

  private addSubscription(subscription: Subscription): void {
    this.subscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
