import { ISpinData } from '../interfaces/spin-data.interface';

export class SpinDataModel {
  readonly bet: number;
  readonly dateTime: string;
  readonly id: string;
  readonly newBalance: number;
  readonly wonAmount: number;
  readonly currency: string;
  readonly betType?: string;
  readonly brandName?: string;
  readonly feature?: string;
  readonly gameName?: string;
  readonly operatorName?: string;
  readonly outcome?: string;
  readonly playerId?: number;
  readonly roundId?: number;
  readonly isNftWin?: number;

  constructor(input: ISpinData) {
    Object.assign(this, {...input});
    // this.bet = input.bet;
    this.dateTime = input.datetime;
    // this.id = input.id;
    // this.newBalance = input.newBalance;
    // this.wonAmount = input.wonAmount;
    // this.betType = input.betType;
    // this.brandName = input.brandName;
    // this.feature = input.feature;
    // this.gameName = input.gameName;
    // this.operatorName = input.operatorName;
    // this.outcome = input.outcome;
    // this.playerId = input.playerId;
    // this.roundId = input.roundId;
    // this.currency = input.currency;
  }
}
