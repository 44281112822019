import { IRewardsData } from '../interfaces/api-resp.interface';
import { RewardDataModel } from "./reward-data.model";
import { IRewordData } from "../interfaces/reword-data.interface";

export class ApiRewardsDataRespModel {
  readonly rewards: RewardDataModel[];

  constructor(input: IRewardsData<IRewordData>) {
    this.rewards = input.levelsWithRewards.map((item) => new RewardDataModel(item));
  }
}
