import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URLS } from '../../constants/urls';
import { map } from 'rxjs/operators';
import { SpinDetailModel } from '../models/spin-detail.model';
import { IApiResp } from '../interfaces/api-resp.interface';
import { ISpinDetail } from '../interfaces/spin-detail.interface';
import { BaseApiService } from './base-api.service';

@Injectable()
export class ApiSpinDetailsService extends BaseApiService {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  public getSpinHistoryDetail(id: string, params): Observable<SpinDetailModel> {
    return this.http.get(API_URLS.spinHistory.byId(id), {
      params: this.formatParams(params),
    })
      .pipe(
        map((res: IApiResp<ISpinDetail>) => {
          return new SpinDetailModel(res.data);
        }),
      );
  }

  public getSpinCurrentSessionDetail(id: number, params): Observable<any> {
    return this.http.get<any>(API_URLS.spinHistory.currentById(id), {
      params: this.formatParams(params),
    })
      .pipe(
        map((res: IApiResp<ISpinDetail>) => {
          return new SpinDetailModel(res.data);
        }),
      );
  }
}
