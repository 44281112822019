import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryRewardsComponent } from './history-rewards.component';
import { HistoryRewardsService } from './history-rewards.service';
import { MatTableModule } from '@angular/material/table';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ComponentsModule } from '../../../shared/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppHistoryRewardsService } from 'src/app/shared/api/services/app-history-rewards.service';

@NgModule({
  declarations: [
    HistoryRewardsComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    InfiniteScrollModule,
    ComponentsModule,
    TranslateModule,
  ],
  providers: [
    AppHistoryRewardsService,
    HistoryRewardsService,
    { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
  ],
  exports: [
    HistoryRewardsComponent,
  ],
})
export class HistoryRewardsModule {
}
