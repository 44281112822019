import { QUEENSLAND_GHOST_WIN_LINES } from './queensland-ghost-win-lines';
import { PIRATE_O_PAY_WIN_LINES } from './pirate-o-pay-win-lines';
import { PANDA_RICHES_WIN_LINES } from './panda-riches-win-lines';
import { GENIE_GOLD_WIN_LINES } from './genie-gold-win-lines';
import { LAPLAND_GIFTS_WIN_LINES } from './lapland-gifts-win-lines';
import { GODS_OF_RICHES_WIN_LINES } from './gods-of-riches-win-lines';

export const GAMES_WIN_LINES: { [key: number]: { [key: string]: string } } = {
  1: QUEENSLAND_GHOST_WIN_LINES,
  2: PIRATE_O_PAY_WIN_LINES,
  3: GENIE_GOLD_WIN_LINES,
  4: LAPLAND_GIFTS_WIN_LINES,
  5: PANDA_RICHES_WIN_LINES,
  6: GODS_OF_RICHES_WIN_LINES,
};
