import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BaseApiService {
  protected formatParams(params): { [key: string]: string } {
    const httpParams = {};
    for (const prop in params) {
      if (params.hasOwnProperty(prop) && String(params[prop])) {
        httpParams[prop] = String(params[prop]);
      }
    }
    return httpParams;
  }
}
