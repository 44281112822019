import { IScatter } from '../interfaces/scatter.interface';

export class ScatterModel {
  readonly symbolId: string;
  readonly count: number;
  readonly freeSpins: number;

  constructor(input: IScatter) {
    this.symbolId = input.symbolId;
    this.count = input.count;
    this.freeSpins = input.freeSpins;
  }
}
