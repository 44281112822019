import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHistoryRewardsService } from 'src/app/shared/api/services/app-history-rewards.service';
import { ApiRewardsDataRespModel } from "../../../shared/api/models/api-rewards-data-resp.model";

@Injectable()
export class HistoryRewardsService {

  constructor(
    private appHistoryRewardsService: AppHistoryRewardsService,
  ) {
  }

  public getDataHistoryRewards(params): Observable<ApiRewardsDataRespModel> {
    return this.appHistoryRewardsService.getRewardsData(params);
  }
}
