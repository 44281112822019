import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { HistoryCurrentService } from './history-current.service';
import { IPagination } from '../../../shared/api/interfaces/pagination-interface';
import { PAGINATION_CONFIG } from '../../../shared/configs/pagination-config';
import { HISTORY_TODAY_PLAYER_TABLE } from '../../../shared/configs/table-headers/history-today-headers.config';
import { SpinDataModel } from '../../../shared/api/models/spin-data.model';

@Component({
  selector: 'app-history-current',
  templateUrl: './history-current.component.html',
  styleUrls: ['./history-current.component.scss'],
})
export class HistoryCurrentComponent implements OnInit, OnDestroy {
  @Output() setSpinId: EventEmitter<number> = new EventEmitter<number>();
  @Output() setSessionId: EventEmitter<string> = new EventEmitter<string>();
  private subscription: Subscription = new Subscription();
  private playerSessionId: string = null;
  public pagination: IPagination = Object.assign({}, PAGINATION_CONFIG);
  public displayedColumns: string[] = HISTORY_TODAY_PLAYER_TABLE;
  public dataSource: SpinDataModel[] = null;
  public totalItems: number;
  public chosenRow: number;
  public infiniteScrollStop = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private historyCurrentService: HistoryCurrentService,
  ) {
  }

  public ngOnInit(): void {
    this.getSessionIdFromLink();
  }

  private getSessionIdFromLink(): void {
    this.addSubscription(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        if (params.sid) {
          this.playerSessionId = params.sid;
          this.setDataCurrentWithCount();
        }
      }),
    );
  }

  private setDataCurrentWithCount(): void {
    this.addSubscription(
      this.historyCurrentService.getDataCurrentHistoryWithCount({...this.pagination, sessionId: this.playerSessionId})
        .subscribe(([dataCurrent, count]) => {
          this.dataSource = dataCurrent.items;
          this.pagination.take = dataCurrent.take;
          this.pagination.skip = dataCurrent.skip;
          this.totalItems = count;
        })
    );
  }

  public onScroll(): void {
    if (this.dataSource.length < this.totalItems) {
      this.pagination.skip += this.pagination.take;
      this.loadMore();
    }
  }

  private loadMore(): void {
    this.addSubscription(
      this.historyCurrentService.getDataCurrentHistoryWithCount({ ...this.pagination, sessionId: this.playerSessionId })
        .subscribe(([dataToday, count]) => {
          this.dataSource = this.dataSource.concat(dataToday.items);
        }),
    );
  }

  public getSpinId(id: number, rowIndex: number): void {
    this.chosenRow = rowIndex;
    this.setSpinId.emit(id);
    this.setSessionId.emit(this.playerSessionId);
  }

  private addSubscription(subscription: Subscription): void {
    this.subscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
