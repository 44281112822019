import { IRewordData } from "../interfaces/reword-data.interface";

export class RewardDataModel {
  readonly id: string;
  readonly wonAt: string;
  readonly name: string;
  readonly description?: string;
  readonly image: string;

  constructor(input: IRewordData) {
    this.wonAt = input.unblockedAt;
    this.id = input.reward.id;
    this.name = input.reward.name;
    this.description = input.reward.description;
    this.image = input.reward.image;
  }
}
