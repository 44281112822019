export const GENIE_GOLD_REELS: { [key: string]: string } = {
  a: '/assets/reel-symbols/genie-gold/scatter.png',
  b: '/assets/reel-symbols/genie-gold/wild.png',
  b_expanded: '/assets/reel-symbols/genie-gold/wild_expanded.png',
  c: '/assets/reel-symbols/genie-gold/h1.png',
  d: '/assets/reel-symbols/genie-gold/h2.png',
  e: '/assets/reel-symbols/genie-gold/h3.png',
  f: '/assets/reel-symbols/genie-gold/h4.png',
  g: '/assets/reel-symbols/genie-gold/h5.png',
  h: '/assets/reel-symbols/genie-gold/a.png',
  i: '/assets/reel-symbols/genie-gold/k.png',
  j: '/assets/reel-symbols/genie-gold/q.png',
  k: '/assets/reel-symbols/genie-gold/j.png',
  l: '/assets/reel-symbols/genie-gold/10.png',
  m: '/assets/reel-symbols/genie-gold/9.png',
};
