import { ApiBaseRespModel } from './api-base-resp.model';
import { SpinDataModel } from './spin-data.model';
import { IData } from '../interfaces/api-resp.interface';
import { ISpinData } from '../interfaces/spin-data.interface';

export class ApiSpinDataRespModel extends ApiBaseRespModel {
  readonly items: SpinDataModel[];
  readonly metadata: string[];

  constructor(input: IData<ISpinData>) {
    super();
    this.items = input.items.map((item) => new SpinDataModel(item));
    this.metadata = input.metadata;
    this.take = Number(input.take);
    this.skip = Number(input.skip);
  }
}
