import { Injectable } from '@angular/core';
import { ApiHistoryTodayService } from '../../../shared/api/services/api-history-today.service';
import { Observable } from 'rxjs';
import { ApiSpinDataRespModel } from '../../../shared/api/models/api-spin-data-resp.model';

@Injectable()
export class HistoryTodayService {

  constructor(
    private apiHistoryTodayService: ApiHistoryTodayService,
  ) { }

  public getDataTodayWithCount(params): Observable<[ApiSpinDataRespModel, number]> {
    return this.apiHistoryTodayService.getDataTodayWithCount(params);
  }
}
