import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinDetailsService } from './spin-details.service';
import { SpinDetailsComponent } from './spin-details.component';
import { ApiSpinDetailsService } from '../../../shared/api/services/api-spin-details.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SpinDetailsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  providers: [
    SpinDetailsService,
    ApiSpinDetailsService,
    { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
  ],
  exports: [
    SpinDetailsComponent,
  ],
})
export class SpinDetailsModule {
}
