import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URLS } from '../../constants/urls';
import { IApiResp } from '../interfaces/api-resp.interface';
import { map } from 'rxjs/operators';
import { IRegularToken } from '../interfaces/regular-token.interface';

@Injectable()
export class ApiGameHistoryService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public getRegularToken(tempToken: string): Observable<string> {
    return this.http.post(API_URLS.token.getRegularToken(tempToken), {})
      .pipe(
        map((res: IApiResp<IRegularToken>) => {
          return res.data.token;
        })
      );
  }
}
