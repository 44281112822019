import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryCurrentComponent } from './history-current.component';
import { HistoryCurrentService } from './history-current.service';
import { AppHistoryCurrentService } from '../../../shared/api/services/app-history-current.service';
import { MatTableModule } from '@angular/material/table';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ComponentsModule } from '../../../shared/components/components.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    HistoryCurrentComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    InfiniteScrollModule,
    ComponentsModule,
    TranslateModule,
  ],
  providers: [
    AppHistoryCurrentService,
    HistoryCurrentService,
    { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
  ],
  exports: [
    HistoryCurrentComponent,
  ],
})
export class HistoryCurrentModule {
}
