export const GODS_OF_RICHES_REELS: { [key: string]: string } = {
  a: '/assets/reel-symbols/gods-of-riches/scatter.png',
  b: '/assets/reel-symbols/gods-of-riches/wild.png',
  c: '/assets/reel-symbols/gods-of-riches/h1.png',
  d: '/assets/reel-symbols/gods-of-riches/h2.png',
  e: '/assets/reel-symbols/gods-of-riches/h3.png',
  f: '/assets/reel-symbols/gods-of-riches/h4.png',
  g: '/assets/reel-symbols/gods-of-riches/a.png',
  h: '/assets/reel-symbols/gods-of-riches/k.png',
  i: '/assets/reel-symbols/gods-of-riches/q.png',
  j: '/assets/reel-symbols/gods-of-riches/j.png',
  k: '/assets/reel-symbols/gods-of-riches/10.png',
};
