import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { API_URLS } from '../../constants/urls';
import { map } from 'rxjs/operators';
import { IApiResp, IRewardsData } from '../interfaces/api-resp.interface';
import { IRewordData } from "../interfaces/reword-data.interface";
import { ApiRewardsDataRespModel } from "../models/api-rewards-data-resp.model";

@Injectable()
export class AppHistoryRewardsService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  public getRewardsData(params): Observable<ApiRewardsDataRespModel> {
    return this.http.get(API_URLS.spinHistory.rewards, {
      params: this.formatParams(params),
    })
      .pipe(
        map((res: IApiResp<IRewardsData<IRewordData>>) => {
          return new ApiRewardsDataRespModel(res.data);
        }),
      );
  }
}
