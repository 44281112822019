export const QUEENSLAND_GHOST_REELS: { [key: string]: string } = {
  a: '/assets/reel-symbols/queensland-ghost/scatter.png',
  b: '/assets/reel-symbols/queensland-ghost/wild.png',
  b_expanded: '/assets/reel-symbols/queensland-ghost/wild_expanded.png',
  c: '/assets/reel-symbols/queensland-ghost/h1.png',
  d: '/assets/reel-symbols/queensland-ghost/h2.png',
  e: '/assets/reel-symbols/queensland-ghost/h3.png',
  f: '/assets/reel-symbols/queensland-ghost/h4.png',
  g: '/assets/reel-symbols/queensland-ghost/h5.png',
  h: '/assets/reel-symbols/queensland-ghost/a.png',
  i: '/assets/reel-symbols/queensland-ghost/k.png',
  j: '/assets/reel-symbols/queensland-ghost/q.png',
  k: '/assets/reel-symbols/queensland-ghost/j.png',
  l: '/assets/reel-symbols/queensland-ghost/10.png',
  m: '/assets/reel-symbols/queensland-ghost/9.png',
};
