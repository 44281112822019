import { Injectable } from '@angular/core';
import { ApiSpinDetailsService } from '../../../shared/api/services/api-spin-details.service';
import { Observable } from 'rxjs';
import { SpinDetailModel } from '../../../shared/api/models/spin-detail.model';

@Injectable()
export class SpinDetailsService {

  constructor(
    private apiSessionDetailsService: ApiSpinDetailsService,
  ) {
  }

  public getSpinHistoryDetail(id: string, params): Observable<SpinDetailModel> {
    return this.apiSessionDetailsService.getSpinHistoryDetail(id, params);
  }

  public getSpinCurrentSessionDetail(id: number, params): Observable<any> {
    return this.apiSessionDetailsService.getSpinCurrentSessionDetail(id, params);
  }
}
