import { Injectable } from '@angular/core';
import { ApiGameHistoryService } from '../../shared/api/services/api-game-history.service';
import { Observable } from 'rxjs';

@Injectable()
export class GameHistoryService {

  constructor(
    private apiAuthService: ApiGameHistoryService,
  ) { }

  public getRegularToken(tempToken: string): Observable<string> {
    return this.apiAuthService.getRegularToken(tempToken);
  }
}
