import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from './base-api.service';
import { forkJoin, Observable } from 'rxjs';
import { API_URLS } from '../../constants/urls';
import { ApiSpinDataRespModel } from '../models/api-spin-data-resp.model';
import { map } from 'rxjs/operators';
import { IApiResp, IData } from '../interfaces/api-resp.interface';
import { ISpinData } from '../interfaces/spin-data.interface';
import { ICount } from '../interfaces/count.interface';

@Injectable()
export class AppHistoryCurrentService extends BaseApiService {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  public getDataCurrentHistoryWithCount(params): Observable<[ApiSpinDataRespModel, number]> {
    const dataToday = this.getDataCurrent(params);
    const count = this.getDataCurrentCount(params);
    return forkJoin<ApiSpinDataRespModel, number>([dataToday, count]);
  }

  private getDataCurrent(params): Observable<ApiSpinDataRespModel> {
    return this.http.get(API_URLS.spinHistory.current, {
      params: this.formatParams(params),
    })
      .pipe(
        map((res: IApiResp<IData<ISpinData>>) => {
          return new ApiSpinDataRespModel(res.data);
        }),
      );
  }

  private getDataCurrentCount(params): Observable<number> {
    return this.http.get(API_URLS.spinHistory.currentCount, {
      params: this.formatParams(params),
    })
      .pipe(
        map((res: IApiResp<ICount>) => {
          return res.data.count;
        }),
      );
  }
}
