import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AuthService {
  private tokenSubj: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public tokenInfo$: Observable<string> = this.tokenSubj.asObservable();

  public setRegularToken(token: string): void {
    return this.tokenSubj.next(token);
  }
}
